import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./style.css";

const Menu = () => {
  const [menuItems1, setMenuItems1] = useState([]);
  const [menuItems2, setMenuItems2] = useState([]);
  const [menuItems3, setMenuItems3] = useState([]);
  const [menuItems4, setMenuItems4] = useState([]);
  const [menuItems5, setMenuItems5] = useState([]);
  const [menuItems6, setMenuItems6] = useState([]);
  const [menuItems7, setMenuItems7] = useState([]);
  const [menuItems8, setMenuItems8] = useState([]);
  const [menuItems9, setMenuItems9] = useState([]);
  const [menuItems10, setMenuItems10] = useState([]);
  const [menuItems11, setMenuItems11] = useState([]);
  const [menuItems12, setMenuItems12] = useState([]);
  const [menuItems13, setMenuItems13] = useState([]);
  const [menuItems14, setMenuItems14] = useState([]);
  const [menuItems15, setMenuItems15] = useState([]);
  const [menuItems16, setMenuItems16] = useState([]);


  useEffect(() => {
    const fetchMenuItems1 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 5, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems1(response.data.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    const fetchMenuItems2 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 6, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems2(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };
    const fetchMenuItems3 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 7, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems3(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems4 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 8, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems4(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems5 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 9, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems5(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems6 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 10, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems6(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };
    
    const fetchMenuItems7 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 11, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems7(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems8 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 12, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems8(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems9 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 13, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems9(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems10 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 14, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems10(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems11 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 15, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems11(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems12 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 16, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems12(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems13 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 17, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems13(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems14 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 18, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems14(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems15 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 19, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems15(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    const fetchMenuItems16 = async () => {
      try {
        const response = await axios.post(
          "/article/list", 
          { article_type_id: 20, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setMenuItems16(response.data.data);
      } catch (error) {
        console.error("Error fetching structure items:", error);
      }
    };

    fetchMenuItems1();
    fetchMenuItems2();
    fetchMenuItems3();
    fetchMenuItems4();
    fetchMenuItems5();
    fetchMenuItems6();
    fetchMenuItems7();
    fetchMenuItems8();
    fetchMenuItems9();
    fetchMenuItems10();
    fetchMenuItems11();
    fetchMenuItems12();
    fetchMenuItems13();
    fetchMenuItems14();
    fetchMenuItems15();
    fetchMenuItems16();
  }, []);

  return (
    <div className="top-menu">
      <nav className="navbar navbar-expand-lg header-fullpage nav-light">
        <button
          className="navbar-toggler x collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link d-none d-lg-inline-block align-self-center"
                href="/"
              >
                Нүүр хуудас
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Бидний тухай{" "}
              </a>
              <ul className="dropdown-menu">
                {menuItems1.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Бүтцийн нэгжүүд{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    {menuItems2.slice().reverse().map((item) => (
                      <li key={item.id}>
                        <a href={`/${item.id}`} className="dropdown-item">
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Хууль эрхзүй{" "}
              </a>
              <ul className="dropdown-menu">
                {menuItems3.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                <li>
                  <a className="dropdown-item" href="https://gia.gov.mn/45/all">
                  Төрийн болон албаны нууц
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Баталгаажуулалт{" "}
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Бидний тухай{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                  {menuItems5.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Эрхзүйн мэдээлэл{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/13071"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Стандартчилал, техникийн зохицуулалт,
                        <br />
                        тохирлын үнэлгээний итгэмжлэлийн тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/102?lawid=102"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Байгууллагын нууцын тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/537"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Хувь хүний нууцын тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/294"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Иргэдээс төрийн байгууллага, албан тушаалтанд
                        <br />
                        гаргасан өргөдөл, гомдлыг шийдвэрлэх тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/13707"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Жагсаалт, журам шинэчлэн батлах тухай тогтоол
                      </a>
                    </li>
                    {menuItems6.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                {menuItems4.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Автотээврийн хэрэгслийн засвар
                    <br />
                    үйлчилгээний баталгаажуулалт{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                  {menuItems7.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Нийтийн зорчигч тээврийн
                    <br />
                    үйлчилгээний баталгаажуулалт{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                  {menuItems8.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Ачаа тээврийн
                    <br />
                    үйлчилгээний баталгаажуулалт{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                {menuItems9.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Үйлчилгээ{" "}
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Цахим үйлчилгээ{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://eticket.transdep.mn/"
                      >
                        Автобусны Тасалбар захиалах
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.autobox.mn"
                      >
                        Авто үйлчилгээний ангилал тогтоолгох
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://teever.transdep.mn/login"
                      >
                        Тээврийн нэгдсэн систем
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://burtgel.transdep.mn"
                      >
                        Тээврийн хэрэгслийн дугаар захиалах
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://auction.transdep.mn"
                      >
                        Дугаарын дуудлага худалдаа
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://logistic.transdep.mn"
                      >
                        Дотоодын ачаа тээвэр
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.transdep.mn/epay"
                      >
                        Бүртгэлийн төлбөрийн програм
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Тээврийн хэрэгсэлд тавигдах шаардлага
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                  {menuItems11.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                {menuItems10.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ил тод байдал{" "}
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Үйл ажиллагаа{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li className="dropdown-submenu">
                      <a
                        href="#"
                        className="dropdown-item dropdown-submenu-toggle icon-left"
                      >
                        Тээвэр зохицуулалт{" "}
                        <svg
                          width="10"
                          height="10"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </a>
                      <ul className="dropdown-menu dropdown-submenu">
                      {menuItems16.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                      </ul>
                    </li>
                    {menuItems13.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Худалдан авах ажиллагаа{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                  {menuItems14.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Хүний нөөц{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                  {menuItems15.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
                  </ul>
                </li>
                {menuItems12.slice().reverse().map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={`/${item.id}`}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item dropdown">
                  <a 
                  className="nav-link d-none d-lg-inline-block align-self-center" 
                  href="/50"
                  >
                    Үнэ тариф
                  </a>
                </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link d-none d-lg-inline-block align-self-center"
                href="https://shilendans.gov.mn/organization/43185"
              >
                Шилэн данс
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link d-none d-lg-inline-block align-self-center"
                href="/13"
              >
                ХОЛБОО БАРИХ
              </a>
            </li>
            
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Menu;
