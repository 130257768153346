// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.language {
  display: flex;
  justify-content: end;
  margin: 0 30px;
}

.language a {
  margin: 0 5px;
}

.language img {
  z-index: 110;
}

@media (max-width: 800px) {
  .language {
    height: auto;
    margin-right: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Language/style.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;EACnB;AACF","sourcesContent":["\n\n.language {\n  display: flex;\n  justify-content: end;\n  margin: 0 30px;\n}\n\n.language a {\n  margin: 0 5px;\n}\n\n.language img {\n  z-index: 110;\n}\n\n@media (max-width: 800px) {\n  .language {\n    height: auto;\n    margin-right: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
