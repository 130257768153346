import React from "react";
import WidgetContact from "../../../WidgetContact";
import Tabs3Image1 from "../../../../used/image/urilga3.jpg";

const TabsPage3 = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
          <div className="text-left">
          <div className="post-content">
            <div className="meta-box">2023-05-04 02:30:16 </div><br/>
            <h6 className="h4-md mb-3 fw-7 txt-blue">
              ТЕНДЕРИЙН УРИЛГА - 2023.05.04
            </h6>
            <p align="justify" />
            <p>
              <img src={Tabs3Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
            </p>
            <p />
          </div>
          </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default TabsPage3;
