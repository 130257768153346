import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./style.css";
import Header from "../Header";
import Topbar from "../Topbar";
import Home from "../Home";
import Contact from "../Contacts";
import Footer from "../Footer";
import Holboo from "../../pages/ZPage/Holboo";
import Slide1Page from "../../pages/ZPage/SlidePage/Slide1Page";
import Slide2Page from "../../pages/ZPage/SlidePage/Slide2Page";
import Slide3Page from "../../pages/ZPage/SlidePage/Slide3Page";
import NewsPage1 from "../../pages/ZPage/NewsPage/NewsPage1";
import NewsPage2 from "../../pages/ZPage/NewsPage/NewsPage2";
import NewsPage3 from "../../pages/ZPage/NewsPage/NewsPage3";
import NewsPage4 from "../../pages/ZPage/NewsPage/NewsPage4";
import NewsPage5 from "../../pages/ZPage/NewsPage/NewsPage5";
import NewsPage6 from "../../pages/ZPage/NewsPage/NewsPage6";
import NewsPage7 from "../../pages/ZPage/NewsPage/NewsPage7";
import NewsPage8 from "../../pages/ZPage/NewsPage/NewsPage8";
import NewsPage9 from "../../pages/ZPage/NewsPage/NewsPage9";
import NewsPage10 from "../../pages/ZPage/NewsPage/NewsPage10";
import ServicePage from "../../pages/ZPage/ServicePage";
import TabsPage1 from "../../pages/ZPage/TabsPage/TabsPage1";
import TabsPage2 from "../../pages/ZPage/TabsPage/TabsPage2";
import TabsPage3 from "../../pages/ZPage/TabsPage/TabsPage3";
import TabsPage4 from "../../pages/ZPage/TabsPage/TabsPage4";
import TabsPage5 from "../../pages/ZPage/TabsPage/TabsPage5";
import TabsPage6 from "../../pages/ZPage/TabsPage/TabsPage6";
import TabsPage7 from "../../pages/ZPage/TabsPage/TabsPage7";
import Bairshil from "../../pages/ZPage/Bairshil";

import Taniltsuulga from "../../pages/Bidnii_tuhai/Taniltsuulga";
import Zorilgo from "../../pages/Bidnii_tuhai/Zorilgo";
import Chig from "../../pages/Bidnii_tuhai/Chig";
import Udirdlaga from "../../pages/Bidnii_tuhai/Udirdlaga";
import Butets from "../../pages/Bidnii_tuhai/Butets";

import AchaaHeltes from "../../pages/Bidnii_tuhai/Negjuud/AchaaHeltes";
import BurtgelHeltes from "../../pages/Bidnii_tuhai/Negjuud/BurtgelHeltes";
import HodolgoonHeltes from "../../pages/Bidnii_tuhai/Negjuud/HodolgoonHeltes";
import HynaltAlba from "../../pages/Bidnii_tuhai/Negjuud/HynaltAlba";
import HynaltHeltes from "../../pages/Bidnii_tuhai/Negjuud/HynaltHeltes";
import SanhuuHeltes from "../../pages/Bidnii_tuhai/Negjuud/SanhuuHeltes";
import SudalgaaTov from "../../pages/Bidnii_tuhai/Negjuud/SudalgaaTov";
import TechnologyHeltes from "../../pages/Bidnii_tuhai/Negjuud/TechnologyHeltes";
import ZahirgaaHeltes from "../../pages/Bidnii_tuhai/Negjuud/ZahirgaaHeltes";
import ZorchigchHeltes from "../../pages/Bidnii_tuhai/Negjuud/ZorchigchHeltes";

import DuremJagsaalt from "../../pages/Huuli_erhzui/DuremJagsaalt";
import DuremJuram from "../../pages/Huuli_erhzui/DuremJuram";
import HemHemjee from "../../pages/Huuli_erhzui/HemHemjee";
import SaidTushaal from "../../pages/Huuli_erhzui/SaidTushaal";
import Standart from "../../pages/Huuli_erhzui/Standart";
import UlsiinHuuli from "../../pages/Huuli_erhzui/UlsiinHuuli";
import ZahiralTushaal from "../../pages/Huuli_erhzui/ZahiralTushaal";
import ZasgiinTogtool from "../../pages/Huuli_erhzui/ZasgiinTogtool";

import AchaaOrgodol from "../../pages/Batalgaajuulalt/AchaaTeever/AchaaOrgodol";
import AchaaSudalgaa from "../../pages/Batalgaajuulalt/AchaaTeever/AchaaSudalgaa";
import AchaaUnelgee from "../../pages/Batalgaajuulalt/AchaaTeever/AchaaUnelgee";

import ChanarBodlogo from "../../pages/Batalgaajuulalt/BidniiTuhai/ChanarBodlogo";
import ShudargaBaidal from "../../pages/Batalgaajuulalt/BidniiTuhai/ShudargaBaidal";
import Taniltsuulga2 from "../../pages/Batalgaajuulalt/BidniiTuhai/Taniltsuulga2";

import Geree from "../../pages/Batalgaajuulalt/ErhzuinMedeelel/Geree";
import Tsutslah from "../../pages/Batalgaajuulalt/ErhzuinMedeelel/Tsutslah";
import UilYavts from "../../pages/Batalgaajuulalt/ErhzuinMedeelel/UilYavts";

import HolbooBarihUtas from "../../pages/Batalgaajuulalt/HolbooBarihUtas";
import UilchilgeeJagsaalt from "../../pages/Batalgaajuulalt/UilchilgeeJagsaalt";
import UneTariff from "../../pages/Batalgaajuulalt/UneTariff";

import ZasvarOrgodol from "../../pages/Batalgaajuulalt/ZasvarUilchilgee/ZasvarOrgodol";
import ZasvarSudalgaa from "../../pages/Batalgaajuulalt/ZasvarUilchilgee/ZasvarSudalgaa";
import ZasvarUnelgee from "../../pages/Batalgaajuulalt/ZasvarUilchilgee/ZasvarUnelgee";

import ZorchigchOrgodol from "../../pages/Batalgaajuulalt/ZorchigchTeever/ZorchigchOrgodol";
import ZorchigchSudalgaa from "../../pages/Batalgaajuulalt/ZorchigchTeever/ZorchigchSudalgaa";
import ZorchigchUnelgee from "../../pages/Batalgaajuulalt/ZorchigchTeever/ZorchigchUnelgee";

import BurduulehJagsaalt from "../../pages/Uilchilgee/BurduulehJagsaalt";
import BurtgelHasuulah from "../../pages/Uilchilgee/BurtgelHasuulah";
import MergeshsenJolooch from "../../pages/Uilchilgee/MergeshsenJolooch";
import UneTarif from "../../pages/Uilchilgee/UneTarif";

import AchaaShaardlaga from "../../pages/Uilchilgee/TavigdahShaardlaga/AchaaShaardlaga";
import JuulchinShaardlaga from "../../pages/Uilchilgee/TavigdahShaardlaga/JuulchinShaardlaga";
import ZorchigchShaardlaga from "../../pages/Uilchilgee/TavigdahShaardlaga/ZorchigchShaardlaga";

import HoorondHeleltseer from "../../pages/Iltod_baidal/HoorondHeleltseer";
import MongolBichig from "../../pages/Iltod_baidal/MongolBichig";
import OyuniiOmch from "../../pages/Iltod_baidal/OyuniiOmch";
import Tailan from "../../pages/Iltod_baidal/Tailan";
import TosolHotolbor from "../../pages/Iltod_baidal/TosolHotolbor";
import TosovSanhuu from "../../pages/Iltod_baidal/TosovSanhuu";

import AvtoUilchilgee from "../../pages/Iltod_baidal/UilAjillagaa/AvtoUilchilgee";
import HynaltiinUzleg from "../../pages/Iltod_baidal/UilAjillagaa/HynaltiinUzleg";
import MergeshsenJoloochTasag from "../../pages/Iltod_baidal/UilAjillagaa/MergeshsenJoloochTasag";
import TeeverBurtgel from "../../pages/Iltod_baidal/UilAjillagaa/TeeverBurtgel";
import TeeverUdirdlaga from "../../pages/Iltod_baidal/UilAjillagaa/TeeverUdirdlaga";

import HynaltTailan from "../../pages/Iltod_baidal/UilAjillagaa/TeeverZohitsuulalt/HynaltTailan";
import OlgosonZovshoorol from "../../pages/Iltod_baidal/UilAjillagaa/TeeverZohitsuulalt/OlgosonZovshoorol";
import Taniltsuulga3 from "../../pages/Iltod_baidal/UilAjillagaa/TeeverZohitsuulalt/Taniltsuulga3";

import H2016 from "../../pages/Iltod_baidal/HudaldanAvah/H2016";
import H2017 from "../../pages/Iltod_baidal/HudaldanAvah/H2017";
import H2018 from "../../pages/Iltod_baidal/HudaldanAvah/H2018";
import H2019 from "../../pages/Iltod_baidal/HudaldanAvah/H2019";
import H2020 from "../../pages/Iltod_baidal/HudaldanAvah/H2020";
import H2021 from "../../pages/Iltod_baidal/HudaldanAvah/H2021";
import H2022 from "../../pages/Iltod_baidal/HudaldanAvah/H2022";
import H2023 from "../../pages/Iltod_baidal/HudaldanAvah/H2023";
import T2301 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23";
import T2302 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (2)";
import T2303 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (3)";
import T2304 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (4)";
import T2305 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (5)";
import T2306 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (6)";
import T2307 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (7)";
import T2308 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (8)";
import T2309 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (9)";
import T2310 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (10)";
import T2311 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (11)";
import T2312 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (12)";
import T2313 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (13)";
import T2314 from "../../pages/Iltod_baidal/HudaldanAvah/H2023/t23 (14)";
import T2201 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22";
import T2202 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (2)";
import T2203 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (3)";
import T2204 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (4)";
import T2205 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (5)";
import T2206 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (6)";
import T2207 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (7)";
import T2208 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (8)";
import T2209 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (9)";
import T2210 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (10)";
import T2211 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (11)";
import T2212 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (12)";
import T2213 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (13)";
import T2214 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (14)";
import T2215 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (15)";
import T2216 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (16)";
import T2217 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (17)";
import T2218 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (18)";
import T2219 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (19)";
import T2220 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (20)";
import T2221 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (21)";
import T2222 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (22)";
import T2223 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (23)";
import T2224 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (24)";
import T2225 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (25)";
import T2226 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (26)";
import T2227 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (27)";
import T2228 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (28)";
import T2229 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (29)";
import T2230 from "../../pages/Iltod_baidal/HudaldanAvah/H2022/t22 (30)";
import T2101 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21";
import T2102 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (2)";
import T2103 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (3)";
import T2104 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (4)";
import T2105 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (5)";
import T2106 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (6)";
import T2107 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (7)";
import T2108 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (8)";
import T2109 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (9)";
import T2110 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (10)";
import T2111 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (11)";
import T2112 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (12)";
import T2113 from "../../pages/Iltod_baidal/HudaldanAvah/H2021/t21 (13)";

import T2001 from "../../pages/Iltod_baidal/HudaldanAvah/H2020/t20";

import T1901 from "../../pages/Iltod_baidal/HudaldanAvah/H2019/t19";
import T1902 from "../../pages/Iltod_baidal/HudaldanAvah/H2019/t19 (2)";
import T1903 from "../../pages/Iltod_baidal/HudaldanAvah/H2019/t19 (3)";

import T1801 from "../../pages/Iltod_baidal/HudaldanAvah/H2018/t18";
import T1802 from "../../pages/Iltod_baidal/HudaldanAvah/H2018/t18 (2)";

import T1701 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17";
import T1702 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (2)";
import T1703 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (3)";
import T1704 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (4)";
import T1705 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (5)";
import T1706 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (6)";
import T1707 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (7)";
import T1708 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (8)";
import T1709 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (9)";
import T1710 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (10)";
import T1711 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (11)";
import T1712 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (12)";
import T1713 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (13)";
import T1714 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (14)";
import T1715 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (15)";
import T1716 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (16)";
import T1717 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (17)";
import T1718 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (18)";
import T1719 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (19)";
import T1720 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (20)";
import T1721 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (21)";
import T1722 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (22)";
import T1723 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (23)";
import T1724 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (24)";
import T1725 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (25)";
import T1726 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (26)";
import T1727 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (27)";
import T1728 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (28)";
import T1729 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (29)";
import T1730 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (30)";
import T1731 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (31)";
import T1732 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (32)";
import T1733 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (33)";
import T1734 from "../../pages/Iltod_baidal/HudaldanAvah/H2017/t17 (34)";

import T1601 from "../../pages/Iltod_baidal/HudaldanAvah/H2016/t16";
import T1602 from "../../pages/Iltod_baidal/HudaldanAvah/H2016/t16 (2)";
import T1603 from "../../pages/Iltod_baidal/HudaldanAvah/H2016/t16 (3)";
import T1604 from "../../pages/Iltod_baidal/HudaldanAvah/H2016/t16 (4)";

import AjliinBair from "../../pages/Iltod_baidal/HuniiNoots/AjliinBair";
import Bodlogo from "../../pages/Iltod_baidal/HuniiNoots/Bodlogo";
import HaagchDurem from "../../pages/Iltod_baidal/HuniiNoots/HaagchDurem";
import HaagchUnelgee from "../../pages/Iltod_baidal/HuniiNoots/HaagchUnelgee";
import OronToo from "../../pages/Iltod_baidal/HuniiNoots/OronToo";
import SetgelHanamj from "../../pages/Iltod_baidal/HuniiNoots/SetgelHanamj";
import Admin from "../../pages/Admin";
import Menu from "../Menu";
import Login from "../../pages/Admin/Login";
import SearchPage from "../../pages/ZPage/SearchPage";
import AllNews from "../../pages/ZPage/AllNews";

function App() {
  const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {

      typeof window !== undefined &&
        window.document.addEventListener("contextmenu", (e) => {
          e.preventDefault();
        });

  }, []);

  document.onkeydown = function (e) {
   // console.log(e.key);
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key === "u") {
      return false;
    }
  };

  const fetchToken = async () => {
    try {
      // Fetch a new token from the server
      const tokenResponse = await axios.get("user/login");
      const { token } = tokenResponse.data;

      // Store the new token in localStorage
      localStorage.setItem("token", token);
      setIsLogged(true);
    } catch (error) {
      console.error("Токен авахад алдаа гарлаа:", error);
      setIsLogged(false);
    }
  };

  useEffect(() => {
    const initializeToken = async () => {
      try {
        const existingToken = localStorage.getItem("token");

        if (existingToken) {
          setIsLogged(true);
        } else {
          await fetchToken();
        }
      } catch (error) {
        console.error("Токен авахад алдаа гарлаа:", error);
        setIsLogged(false);
      }
    };

    initializeToken();

    const intervalId = setInterval(fetchToken, 3600000);

    return () => clearInterval(intervalId);
  }, []);
  

  if (!isLogged) {
    return (
      <Provider store={store}>
        <Routes>
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Provider>
    );
  } else {
    return (
      <Provider store={store}>
        <div className="App">
          <Topbar />
          <Header />
          <Menu />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={<Login setIsLogged={setIsLogged} />}
            />

            {/* Bidnii_tuhai */}
            <Route path="/8" element={<Taniltsuulga />} />
            <Route path="/9" element={<Zorilgo />} />
            <Route path="/10" element={<Chig />} />
            <Route path="/11" element={<Udirdlaga />} />
            <Route path="/12" element={<Butets />} />

            {/* Negjuud */}
            <Route path="/14" element={<SudalgaaTov />} />
            <Route path="/15" element={<HynaltAlba />} />
            <Route path="/16" element={<ZahirgaaHeltes />} />
            <Route path="/17" element={<TechnologyHeltes />} />
            <Route path="/18" element={<SanhuuHeltes />} />
            <Route path="/19" element={<BurtgelHeltes />} />
            <Route path="/20" element={<HynaltHeltes />} />
            <Route path="/21" element={<ZorchigchHeltes />} />
            <Route path="/22" element={<AchaaHeltes />} />
            <Route path="/23" element={<HodolgoonHeltes />} />

            {/* Huuli_erhzui */}
            <Route path="/24" element={<UlsiinHuuli />} />
            <Route path="/25" element={<ZasgiinTogtool />} />
            <Route path="/26" element={<SaidTushaal />} />
            <Route path="/27" element={<ZahiralTushaal />} />
            <Route path="/28" element={<DuremJuram />} />
            <Route path="/29" element={<Standart />} />
            <Route path="/30" element={<HemHemjee />} />
            <Route path="/31" element={<DuremJagsaalt />} />

            {/* BidniiTuhai */}
            <Route path="/32" element={<Taniltsuulga2 />} />
            <Route path="/33" element={<ChanarBodlogo />} />
            <Route path="/34" element={<ShudargaBaidal />} />

            {/* ErhzuinMedeelel */}
            <Route path="/35" element={<UilYavts />} />
            <Route path="/36" element={<Tsutslah />} />
            <Route path="/37" element={<Geree />} />

            <Route path="/38" element={<UneTariff />} />

            {/* ZasvarUilchilgee */}
            <Route path="/39" element={<ZasvarOrgodol />} />
            <Route path="/40" element={<ZasvarSudalgaa />} />
            <Route path="/41" element={<ZasvarUnelgee />} />

            {/* ZorchigchTeever */}
            <Route path="/42" element={<ZorchigchOrgodol />} />
            <Route path="/43" element={<ZorchigchSudalgaa />} />
            <Route path="/44" element={<ZorchigchUnelgee />} />

            {/* AchaaTeever */}
            <Route path="/45" element={<AchaaOrgodol />} />
            <Route path="/46" element={<AchaaSudalgaa />} />
            <Route path="/47" element={<AchaaUnelgee />} />

            <Route path="/48" element={<UilchilgeeJagsaalt />} />
            <Route path="/49" element={<HolbooBarihUtas />} />

            {/* Uilchilgee */}
            <Route path="/50" element={<UneTarif />} />
            {/* TavigdahShaardlaga */}
            <Route path="/51" element={<AchaaShaardlaga />} />
            <Route path="/52" element={<JuulchinShaardlaga />} />
            <Route path="/53" element={<ZorchigchShaardlaga />} />
            <Route path="/54" element={<BurtgelHasuulah />} />
            <Route path="/55" element={<MergeshsenJolooch />} />
            <Route path="/56" element={<BurduulehJagsaalt />} />

            {/* Iltod_baidal */}

            {/* UilAjillagaa */}

            <Route path="/57" element={<HynaltiinUzleg />} />
            {/* TeeverZohitsuulalt */}
            <Route path="/58" element={<Taniltsuulga3 />} />
            <Route path="/59" element={<OlgosonZovshoorol />} />
            <Route path="/60" element={<HynaltTailan />} />

            <Route path="/61" element={<TeeverUdirdlaga />} />
            <Route path="/62" element={<TeeverBurtgel />} />
            <Route path="/63" element={<AvtoUilchilgee />} />
            <Route path="/64" element={<MergeshsenJoloochTasag />} />

            {/* HudaldanAvah */}
            <Route path="/65" element={<H2016 />} />
            <Route path="/66" element={<H2017 />} />
            <Route path="/67" element={<H2018 />} />
            <Route path="/68" element={<H2019 />} />
            <Route path="/69" element={<H2020 />} />
            <Route path="/70" element={<H2021 />} />
            <Route path="/71" element={<H2022 />} />
            <Route path="/72" element={<H2023 />} />
            <Route path="/100" element={<T2301 />} />
            <Route path="/101" element={<T2302 />} />
            <Route path="/102" element={<T2303 />} />
            <Route path="/103" element={<T2304 />} />
            <Route path="/104" element={<T2305 />} />
            <Route path="/105" element={<T2306 />} />
            <Route path="/106" element={<T2307 />} />
            <Route path="/107" element={<T2308 />} />
            <Route path="/108" element={<T2309 />} />
            <Route path="/109" element={<T2310 />} />
            <Route path="/110" element={<T2311 />} />
            <Route path="/111" element={<T2312 />} />
            <Route path="/112" element={<T2313 />} />
            <Route path="/113" element={<T2314 />} />

            <Route path="/114" element={<T2201 />} />
            <Route path="/115" element={<T2202 />} />
            <Route path="/116" element={<T2203 />} />
            <Route path="/117" element={<T2204 />} />
            <Route path="/118" element={<T2205 />} />
            <Route path="/119" element={<T2206 />} />
            <Route path="/120" element={<T2207 />} />
            <Route path="/121" element={<T2208 />} />
            <Route path="/122" element={<T2209 />} />
            <Route path="/123" element={<T2210 />} />
            <Route path="/124" element={<T2211 />} />
            <Route path="/125" element={<T2212 />} />
            <Route path="/126" element={<T2213 />} />
            <Route path="/127" element={<T2214 />} />
            <Route path="/128" element={<T2215 />} />
            <Route path="/129" element={<T2216 />} />
            <Route path="/130" element={<T2217 />} />
            <Route path="/131" element={<T2218 />} />
            <Route path="/132" element={<T2219 />} />
            <Route path="/133" element={<T2220 />} />
            <Route path="/134" element={<T2221 />} />
            <Route path="/135" element={<T2222 />} />
            <Route path="/136" element={<T2223 />} />
            <Route path="/137" element={<T2224 />} />
            <Route path="/138" element={<T2225 />} />
            <Route path="/139" element={<T2226 />} />
            <Route path="/140" element={<T2227 />} />
            <Route path="/141" element={<T2228 />} />
            <Route path="/142" element={<T2229 />} />
            <Route path="/143" element={<T2230 />} />

            <Route path="/144" element={<T2101 />} />
            <Route path="/145" element={<T2102 />} />
            <Route path="/146" element={<T2103 />} />
            <Route path="/147" element={<T2104 />} />
            <Route path="/148" element={<T2105 />} />
            <Route path="/149" element={<T2106 />} />
            <Route path="/150" element={<T2107 />} />
            <Route path="/151" element={<T2108 />} />
            <Route path="/152" element={<T2109 />} />
            <Route path="/153" element={<T2110 />} />
            <Route path="/154" element={<T2111 />} />
            <Route path="/155" element={<T2112 />} />
            <Route path="/156" element={<T2113 />} />

            <Route path="/157" element={<T2001 />} />

            <Route path="/158" element={<T1901 />} />
            <Route path="/159" element={<T1902 />} />
            <Route path="/160" element={<T1903 />} />

            <Route path="/161" element={<T1801 />} />
            <Route path="/162" element={<T1802 />} />

            <Route path="/163" element={<T1701 />} />
            <Route path="/164" element={<T1702 />} />
            <Route path="/165" element={<T1703 />} />
            <Route path="/166" element={<T1704 />} />
            <Route path="/167" element={<T1705 />} />
            <Route path="/168" element={<T1706 />} />
            <Route path="/169" element={<T1707 />} />
            <Route path="/170" element={<T1708 />} />
            <Route path="/171" element={<T1709 />} />
            <Route path="/172" element={<T1710 />} />
            <Route path="/173" element={<T1711 />} />
            <Route path="/174" element={<T1712 />} />
            <Route path="/175" element={<T1713 />} />
            <Route path="/176" element={<T1714 />} />
            <Route path="/177" element={<T1715 />} />
            <Route path="/178" element={<T1716 />} />
            <Route path="/179" element={<T1717 />} />
            <Route path="/180" element={<T1718 />} />
            <Route path="/181" element={<T1719 />} />
            <Route path="/182" element={<T1720 />} />
            <Route path="/183" element={<T1721 />} />
            <Route path="/184" element={<T1722 />} />
            <Route path="/185" element={<T1723 />} />
            <Route path="/186" element={<T1724 />} />
            <Route path="/187" element={<T1725 />} />
            <Route path="/188" element={<T1726 />} />
            <Route path="/189" element={<T1727 />} />
            <Route path="/190" element={<T1728 />} />
            <Route path="/191" element={<T1729 />} />
            <Route path="/192" element={<T1730 />} />
            <Route path="/193" element={<T1731 />} />
            <Route path="/194" element={<T1732 />} />
            <Route path="/195" element={<T1733 />} />
            <Route path="/196" element={<T1734 />} />

            <Route path="/197" element={<T1601 />} />
            <Route path="/198" element={<T1602 />} />
            <Route path="/199" element={<T1603 />} />
            <Route path="/200" element={<T1604 />} />

            <Route path="/73" element={<TosovSanhuu />} />

            {/* HuniiNoots */}
            <Route path="/74" element={<Bodlogo />} />
            <Route path="/75" element={<HaagchUnelgee />} />
            <Route path="/76" element={<HaagchDurem />} />
            <Route path="/77" element={<AjliinBair />} />
            <Route path="/78" element={<OronToo />} />
            <Route path="/79" element={<SetgelHanamj />} />

            <Route path="/80" element={<HoorondHeleltseer />} />
            <Route path="/81" element={<TosolHotolbor />} />
            <Route path="/82" element={<Tailan />} />
            <Route path="/83" element={<MongolBichig />} />
            <Route path="/89" element={<OyuniiOmch />} />

            {/* Medee */}
            <Route path="/90" element={<NewsPage1 />} />
            <Route path="/91" element={<NewsPage2 />} />
            <Route path="/92" element={<NewsPage3 />} />
            <Route path="/93" element={<NewsPage4 />} />
            <Route path="/94" element={<NewsPage5 />} />
            <Route path="/95" element={<NewsPage6 />} />
            <Route path="/96" element={<NewsPage7 />} />
            <Route path="/97" element={<NewsPage8 />} />
            <Route path="/98" element={<NewsPage9 />} />
            <Route path="/99" element={<NewsPage10 />} />

            <Route path="/1000" element={<AllNews />} />
            <Route path="/1001" element={<Slide1Page />} />
            <Route path="/1002" element={<Slide2Page />} />
            <Route path="/1003" element={<Slide3Page />} />
            <Route path="/1004" element={<Bairshil />} />
            <Route path="/13" element={<Holboo />} />
            <Route path="/1008" element={<ServicePage />} />
            <Route path="/1009" element={<TabsPage1 />} />
            <Route path="/1010" element={<TabsPage2 />} />
            <Route path="/1011" element={<TabsPage3 />} />
            <Route path="/1012" element={<TabsPage4 />} />
            <Route path="/1013" element={<TabsPage5 />} />
            <Route path="/1014" element={<TabsPage6 />} />
            <Route path="/1015" element={<TabsPage7 />} />
            <Route path="/search" element={<SearchPage />} />

          </Routes>
          <Contact />
          <Footer />
        </div>
      </Provider>
    );
  }
}

export default App;
