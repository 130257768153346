import React from "react";
import WidgetContact from "../../../WidgetContact";
import Slide3Image1 from "../../../../used/image/slide 2-1.jpg";
import Slide3Image2 from "../../../../used/image/slide 2-2.jpg";
import Slide3Image3 from "../../../../used/image/slide 2-3.jpg";

const Slide2Page = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div className="post-content">
        <div className="text-left">
  <div className="meta-box">2024-09-09 14:46:12</div><br/>
  <h6 className="h4-md mb-3 fw-7 txt-blue">МОНГОЛ УЛС, БНСУ-ЫН ТЭЭВРИЙН БАЙГУУЛЛАГУУД ХАМТРАН АЖИЛЛАХ ХАРИЛЦАН ОЙЛГОЛЦЛЫН САНАМЖ БИЧИГТ ГАРЫН ҮСЭГ ЗУРЛАА</h6>
  <p align="justify">“Монгол улсын автотээврийн техникийн хяналтын үзлэгийн чадавхыг бэхжүүлж, техникийн хяналтын үзлэгийн тоног төхөөрөмжийг сайжруулах замаар замын хөдөлгөөний аюулгүй байдлыг сайжруулах төсөл”-ийн  хүрээнд Автотээврийн үндэсний төв (ТӨҮГ) ,  БНСУ-ын Замын хөдөлгөөний аюулгүй байдлын газар  (KoRoad)-тай  замын хөдлөгөөний аюулгүй байдлын чиглэлээр хамтран ажиллах САНАМЖ БИЧИГ-т гарын үсэг зурж, соёрхон баталгаажууллаа. 
Сүүлийн жилүүдэд манай улсын нийгэм, эдийн засгийн үзүүлэлт бүх төрөл дээр өсөн нэмэгдэж байгаа боловч анхаарвал зохих сөрөг үр дагаварууд ихээр гарч байгаа юм. 
Үүний нэг нь МУ-ын хэмжээнд гарч буй Зам, тээврийн ослын үр дагавар юм. Улсын хэмжээнд жил бүр ЗТО-ын шинжтэй 29 мянган дуудлага бүртгэгдэж ослын улмаас 3000 орчим хүн ямар нэгэн байдлаар гэмтэж, 600 хүн амь насаа алдаж байна. 
Энэхүү санамж бичгийн хүрээнд МУ-ын замын хөдөлгөөний аюулгүй байдлын гүйцэтгэлийг сайжруулах, мэргэшсэн жолоочын шалгалт, менежментийн тогтолцоог хөгжүүлэх, хөдөлгөөний аюулгүй байдлын боловсрол олгох, мэргэжилтэн бэлтгэх, олон нийтийн мэдлэг, мэдээллийг сайжруулах зэрэг олон төсөл хөтөлбөрийг хамтран хэрэгжүүлэх замаар МУ-ын замын хөдөлгөөний аюулгүй байдлыг хангахад хувь нэмрээ оруулах юм.
Алтан шар зам тань өлзийтэй байх болтугай</p>
  <div><img src={Slide3Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image3} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
</div>
</div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Slide2Page;