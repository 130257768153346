import React, { useEffect, useState } from "react";
import "./style.css";
import SlideImage1 from "../../used/image/slide 1.jpg";
import SlideImage2 from "../../used/image/slide 2.jpg";
import SlideImage3 from "../../used/image/slide 3.jpg";
import Heseg1 from "../../used/image/heseg1.jpg";
import Heseg2 from "../../used/image/heseg2.jpg";

const slides = [
  { src: SlideImage1 },
  { src: SlideImage2 },
  { src: SlideImage3 },
];

const Slide = ({ interval = 6000 }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    console.log("Current Slide Index:", currentSlideIndex);
    const slideInterval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [currentSlideIndex, interval]);

  const handleIndicatorClick = (index) => {
    setCurrentSlideIndex(index);
  };
  
  const titles = [
    "Монгол Улсын Автотээврийн төлөөлөгчид болон БНХАУ-ын ӨМӨЗО-ны Зам, харилцаа тээврийн төлөөлөгчдийн 35 дах ээлжит уулзалт",
    "МОНГОЛ УЛС, БНСУ-ЫН ТЭЭВРИЙН БАЙГУУЛЛАГУУД ХАМТРАН АЖИЛЛАХ ХАРИЛЦАН ОЙЛГОЛЦЛЫН САНАМЖ БИЧИГТ ГАРЫН ҮСЭГ ЗУРЛАА",
    "ОРХОН АЙМГИЙН ШИНЭ АВТОБУУДАЛ АШИГЛАЛТАД ОРЛОО",
  ];

  const paragraphs = [
    "Монгол Улсын Автотээврийн төлөөлөгчид болон Бүгд Найрамдах Хятад Ард Улсын Өвөрмонголын Өөртөө Засах Орны Зам, харилцаа тээврийн төлөөлөгчдийн 35 дах ээлжит уулзалт 09 дүгээр сарын 04-ний өдрөөс 07-ны өдрүүдэд Бүгд Найрамдах Хятад Ард Улсын Ордос хотод амжилттай зохион байгуулагдав.",
    "“Монгол улсын автотээврийн техникийн хяналтын үзлэгийн чадавхыг бэхжүүлж, техникийн хяналтын үзлэгийн тоног төхөөрөмжийг сайжруулах замаар замын хөдөлгөөний аюулгүй байдлыг сайжруулах төсөл”-ийн  хүрээнд Автотээврийн үндэсний төв (ТӨҮГ) ,  БНСУ-ын Замын хөдөлгөөний аюулгүй байдлын газар  (KoRoad)-тай  замын хөдлөгөөний аюулгүй байдлын чиглэлээр хамтран ажиллах САНАМЖ БИЧИГ-т гарын үсэг зурж, соёрхон баталгаажууллаа. ",
    "Автотээврийн үндэсний төв нь Монгол улсын 21 аймаг, 2 сумд үйл ажиллагаагаа явуулдаг. Эдгээрийн нэг Орхон аймагт 4-р зэрэглэлийн автобуудлыг  ашиглалтад орууллаа.\n Орхон аймгийн зорчигч урсгал жил ирэх тусам өссөөр байна.",
    
  ];

  const url = [
    "/1001",
    "/1002",
    "/1003",
  ];

  const renderMultilineText = (text) => {
    return text.split("\n").map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="slide">
      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={
              currentSlideIndex === index
                ? "carousel-item active"
                : "carousel-item"
            }
          >
            <picture className="slide-images">
              <img className="heseg1" src={Heseg1} alt="" />
              <img className="heseg3" src={slide.src} alt={`Slide ${index + 1}`} />
              <img className="heseg2" src={Heseg2} alt="" />
            </picture>
            <div className="carousel-caption fadeInDown">
              <h5><strong>{renderMultilineText(titles[index])}</strong></h5>
              <p>{renderMultilineText(paragraphs[index])}</p>
              <a href={url[index]} className="btn btn-light btn-lg">
                ЦААШ УНШИХ
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="carousel-indicators">
        {slides.map((_, index) => (
          <button
            key={index}
            className={currentSlideIndex === index ? "active" : ""}
            onClick={() => handleIndicatorClick(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slide;
