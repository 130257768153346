// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor {
  width: 1200px;
}

.save-button {
    padding: 10px 20px;
    background-color: #0a2458;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  
  .post-title-input {
    width: 100%;
  }

  .attachment-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .attachment-image {
    max-width: 200px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Admin/Editor/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".editor {\n  width: 1200px;\n}\n\n.save-button {\n    padding: 10px 20px;\n    background-color: #0a2458;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .save-button:hover {\n    background-color: #0056b3;\n  }\n  \n  .post-title-input {\n    width: 100%;\n  }\n\n  .attachment-images {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n  }\n  \n  .attachment-image {\n    max-width: 200px;\n    height: auto;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
