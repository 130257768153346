import React, { useEffect, useState } from "react";
import "./style.css";
import English from "../../used/image/eng.webp";
import Mongolian from "../../used/image/mng.png";

const Language = () => {
  const [isTranslateReady, setIsTranslateReady] = useState(false);

  useEffect(() => {
    const checkIfTranslateLoaded = setInterval(() => {
      const select = document.querySelector('.goog-te-combo');
      if (select) {
        clearInterval(checkIfTranslateLoaded);
        setIsTranslateReady(true);
        const googleTranslateForm = document.querySelector("#google_translate_element");
        if (googleTranslateForm) {
          googleTranslateForm.style.display = "none";
        }
      }
    }, 500);

    return () => clearInterval(checkIfTranslateLoaded);
  }, []);

  const handleTranslate = (langCode) => (e) => {
    e.preventDefault();
    const select = document.querySelector('.goog-te-combo');
    if (select) {
      select.value = langCode;
      select.dispatchEvent(new Event('change'));
    }
  };

  return (
    <div className="language">
      <a href="#" onClick={handleTranslate('mn')}>
        <img alt="Mongolian language" src={Mongolian} />
      </a>
      <a href="#" onClick={handleTranslate('en')}>
        <img alt="English language" src={English} />
      </a>
    </div>
  );
};

export default Language;
