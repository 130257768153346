import React, { useState, useEffect, useCallback } from "react";
import axios from "../../../axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./style.css";

const Editor = ({ itemId }) => {
  const token = localStorage.getItem("token");

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [editorData, setEditorData] = useState("");
  const [id, setId] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [file, setFile] = useState(null);

  const fetchData = useCallback(async (itemId) => {
    try {
      if (!token) {
        throw new Error("No valid token found");
      }
      const response = await axios.post(
        "article/data",
        { id: itemId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedData = response.data.data;
      setData(fetchedData);
      setTitle(fetchedData.title);
      setEditorData(fetchedData.content);
      setId(fetchedData.id);
      setAttachments(fetchedData.attachment || []);
    } catch (error) {
      setError(error);
    }
  }, [token]);

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('title', title);
      formData.append('content', editorData);
      formData.append('attachments', JSON.stringify(attachments));

      if (file) {
        formData.append('file', file);
      }

      await axios.post(
        "article/edit",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Амжилттай засагдлаа.");
    } catch (error) {
      console.error("Алдаатай байна.", error);
      alert("Алдаатай байна.");
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        "article/edit",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        return {
          default: data.url,
        };
      } else {
        throw new Error(data.message || "Зургийг оруулахад алдаа гарлаа");
      }
    } catch (error) {
      console.error("Зургийг оруулахад алдаа гарлаа:", error);
      return null;
    }
  };

  useEffect(() => {
    if (itemId !== null) {
      fetchData(itemId);
    }
  }, [itemId, fetchData]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!data) {
    return <div className="text-center">Цэсээ сонгоорой.</div>;
  } else {
    return (
      <div className="editor">
        <input
          type="text"
          className="post-title-input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <CKEditor
          editor={ClassicEditor}
          config={{
            ckfinder: {
              uploadUrl: "article/edit",
            },
            image: {
              toolbar: [
                "imageTextAlternative",
                "imageStyle:full",
                "imageStyle:side",
              ],
            },
          }}
          data={editorData}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
          onReady={(editor) => {
            editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
              return {
                upload: () => loader.file
                  .then(file => handleImageUpload(file))
                  .catch(() => Promise.reject("File load error")),
                abort: () => {},
              };
            };
          }}
        />
        <input
          type="file"
          onChange={handleFileChange}
        />
        <button onClick={handleSave} className="save-button">
          Save
        </button>
        {attachments.length > 0 && (
          <div className="attachments">
            <h3>Attachments:</h3>
            <div className="attachment-images">
              {attachments.map((attachment) => {
                const isImage = attachment.content_type.startsWith("image/");
                return isImage ? (
                  <img
                    key={attachment.id}
                    src={attachment.url}
                    alt={`Attachment ${attachment.id}`}
                    className="attachment-image"
                  />
                ) : (
                  <a
                    key={attachment.id}
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="attachment-link"
                  >
                    {attachment.url}
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Editor;
