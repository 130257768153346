import React from "react";
import WidgetContact from "../../../WidgetContact";
import Slide3Image1 from "../../../../used/image/slide 3-1.jpg";
import Slide3Image2 from "../../../../used/image/slide 3-2.jpg";
import Slide3Image3 from "../../../../used/image/slide 3-3.jpg";
import Slide3Image4 from "../../../../used/image/slide 3-4.jpg";
import Slide3Image5 from "../../../../used/image/slide 3-5.jpg";
import Slide3Image6 from "../../../../used/image/slide 3-6.jpg";
import Slide3Image7 from "../../../../used/image/slide 3-7.jpg";
import Slide3Image8 from "../../../../used/image/slide 3-8.jpg";
import Slide3Image9 from "../../../../used/image/slide 3-9.jpg";
import Slide3Image10 from "../../../../used/image/slide 3-10.jpg";
import Slide3Image11 from "../../../../used/image/slide 3-11.jpg";
import Slide3Image12 from "../../../../used/image/slide 3-12.jpg";
import Slide3Image13 from "../../../../used/image/slide 3-13.jpg";

const Slide1Page = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div className="post-content">
        <div className="text-left">
  <div className="meta-box">2024-08-28 15:01:12</div><br/>
  <h6 className="h4-md mb-3 fw-7 txt-blue">ОРХОН АЙМГИЙН ШИНЭ АВТОБУУДАЛ АШИГЛАЛТАД ОРЛОО</h6>
  <p align="justify" />
  <p align="justify">Автотээврийн үндэсний төв нь Монгол улсын 21 аймаг, 2 сумд үйл ажиллагаагаа явуулдаг. Эдгээрийн нэг Орхон аймагт 4-р зэрэглэлийн автобуудлыг  ашиглалтад орууллаа. 
Орхон аймгийн зорчигч урсгал жил ирэх тусам өссөөр байна. 2020онд 82,675 зорчигч тээвэрлэж байсан бол 2024 онд энэ тоо 40 гаруй хувиар өсөхийг статистик тоо харуулж байна.
Өдөрт дунджаар 120 зорчигч хот хоорондын тээвэрлэлтийн үйлчилгээг авч байгаа ба бид цаашид найдвартай аюулгүй тээвэрлэлтийг хэвийн үргэлжлүүлэн зорчигчийн ая тухтай байдлыг ханган автобуудлын стандартыг илүү  сайжруулахаар зорин ажиллана.<br/>
Алтан шар зам тань өлзийтэй байх болтугай</p>
  <div><img src={Slide3Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image3} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image4} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image5} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image6} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image7} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image8} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image9} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image10} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image11} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image12} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image13} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
</div>
</div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Slide1Page;
