import React from "react";
import WidgetContact from "../../../WidgetContact";
import Slide3Image1 from "../../../../used/image/slide 1-1.jpg";
import Slide3Image2 from "../../../../used/image/slide 1-2.jpg";
import Slide3Image3 from "../../../../used/image/slide 1-3.jpg";
import Slide3Image4 from "../../../../used/image/slide 1-4.jpg";
import Slide3Image5 from "../../../../used/image/slide 1-5.jpg";
import Slide3Image6 from "../../../../used/image/slide 1-6.jpg";
import Slide3Image7 from "../../../../used/image/slide 1-7.jpg";


const Slide1Page = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div className="post-content">
        <div className="text-left">
  <div className="meta-box">2024-09-09 14:46:12</div><br/>
  <h6 className="h4-md mb-3 fw-7 txt-blue">Монгол Улсын Автотээврийн төлөөлөгчид болон БНХАУ-ын ӨМӨЗО-ны Зам, харилцаа тээврийн төлөөлөгчдийн 35 дах ээлжит уулзалт</h6>
  <p align="justify" />
  <p align="justify">Монгол Улсын Автотээврийн төлөөлөгчид болон Бүгд Найрамдах Хятад Ард Улсын Өвөрмонголын Өөртөө Засах Орны Зам, харилцаа тээврийн төлөөлөгчдийн 35 дах ээлжит уулзалт 09 дүгээр сарын 04-ний өдрөөс 07-ны өдрүүдэд Бүгд Найрамдах Хятад Ард Улсын Ордос хотод амжилттай зохион байгуулагдав.
  Тус уулзалтаар Бүгд найрамдах Хятад ард улс болон Монгол улс хоёрын хооронд хийгдэж буй ачаа, зорчигч тээвэрлэлтийн үйлчилгээг зохион байгуулах, сайжруулах чиглэлээр олон ажлуудыг хамтран хийж хэрэгжүүлэхээр харилцан тохиролцлоо.</p>
  <div><img src={Slide3Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image3} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image4} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image5} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image6} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <p>&nbsp;</p>
  <div><img src={Slide3Image7} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
</div>
</div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Slide1Page;
