import React, { useState, useEffect } from 'react';
import axios from '../../../axios';
import "./style.css";
import WidgetContact from "../../WidgetContact";

const Holboo = () => {
  const token = localStorage.getItem('token');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          'article/data',
          { id: 13 },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }
        );
        setData(response.data.data);
      } catch (error) {
        setError(error);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!data) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className='post-main'>
      <div className='post-container'>
      <div>
        <div className="post-content" dangerouslySetInnerHTML={{ __html: data.content }} /></div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
  );
}
};

export default Holboo;
