import React, { useState, useEffect } from "react";
import axios from "../../../../axios";
import WidgetContact from "../../../WidgetContact";

const H2016 = () => {
  const token = localStorage.getItem("token");
  const [contentItems, setContentItems] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "article/data",
          { id: 68 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const htmlContent = response.data.data.content;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");

        // Extract elements with the class `blog-standard`
        const extractedItems = Array.from(doc.querySelectorAll(".blog-standard")).map(item => item.outerHTML);

        if (extractedItems.length === 0) {
          // If no elements with `blog-standard`, use the whole content or some fallback
          setContentItems([htmlContent]);
        } else {
          setContentItems(extractedItems);
        }
      } catch (error) {
        setError(error);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!contentItems.length) {
    return <div>Loading...</div>;
  } else {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = contentItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
      <div className="post-main">
        <div className="post-container">
          <div className="post-content">
            {currentItems.map((item, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
            <div style={{ padding: "10px 20px 0px", borderTop: "dotted 1px #CCC" }}></div>
            <ul className="pagination">
              <li className={currentPage === 1 ? "disabled" : ""}>
                <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                  Өмнөх
                </button>
              </li>
              {Array.from({ length: Math.ceil(contentItems.length / itemsPerPage) }, (_, i) => (
                <li key={i + 1} className={currentPage === i + 1 ? "active" : ""}>
                  <button onClick={() => paginate(i + 1)}>{i + 1}</button>
                </li>
              ))}
              <li className={currentPage === Math.ceil(contentItems.length / itemsPerPage) ? "disabled" : ""}>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === Math.ceil(contentItems.length / itemsPerPage)}
                >
                  Дараах
                </button>
              </li>
            </ul>
          </div>
          <div>
            <WidgetContact />
          </div>
        </div>
      </div>
    );
  }
};

export default H2016;
