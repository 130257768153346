// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.topbar {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  display: flex;
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;
  z-index: 100;
  color: #555;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.top-sanal {
  position: absolute;
  width: 130px;
  color: #999;
}

.searchnews {
  position: absolute;
  left: 32%;
}

@media (max-width: 800px) {

  .searchnews {
    width: 100px;
  }
}

@media  (min-width: 2000px) {

  .searchnews {
    left: 47%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Topbar/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,WAAW;EACX,wCAAwC;EACxC,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;;EAEE;IACE,YAAY;EACd;AACF;;AAEA;;EAEE;IACE,SAAS;EACX;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.topbar {\n  height: 30px;\n  line-height: 30px;\n  font-size: 13px;\n  display: flex;\n  width: 100%;\n  background-color: rgba(242, 242, 242, 1);\n  box-sizing: border-box;\n  z-index: 100;\n  color: #555;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);\n}\n\n.top-sanal {\n  position: absolute;\n  width: 130px;\n  color: #999;\n}\n\n.searchnews {\n  position: absolute;\n  left: 32%;\n}\n\n@media (max-width: 800px) {\n\n  .searchnews {\n    width: 100px;\n  }\n}\n\n@media  (min-width: 2000px) {\n\n  .searchnews {\n    left: 47%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
