// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.holboo-text {
    background: rgba(242, 242, 242, 1);
    align-items: center;
    margin: 10px;
    padding: 50px;
}

.holboo-title {
    border-bottom: solid 1px #ff9e05;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ZPage/Holboo/style.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,oBAAoB;AACxB","sourcesContent":[".holboo-text {\n    background: rgba(242, 242, 242, 1);\n    align-items: center;\n    margin: 10px;\n    padding: 50px;\n}\n\n.holboo-title {\n    border-bottom: solid 1px #ff9e05;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
