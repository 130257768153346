import React from "react";
import "./style.css";
import CountUp from "react-countup";
import PictureInfo1 from "../../used/image/info1.gif";
import PictureInfo2 from "../../used/image/info2.gif";
import PictureInfo3 from "../../used/image/info3.gif";
import PictureInfo4 from "../../used/image/info4.gif";

const Infograph = () => {
  return (
      <div className="infograph">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3 col-sm-6 fadeInLeft">
              <p>
                <img src={PictureInfo1} height="60px" alt="zz" />
              </p>
              <span className="counter">
                <CountUp start={0} end={297} duration={3} delay={0} />
              </span>
              <div>Дотоодын чиглэл</div>
            </div>

            <div className="col-6 col-lg-3 col-sm-6 fadeInLeft">
              <p>
                <img src={PictureInfo2} height="60px" alt="zz" />
              </p>
              <span className="counter">
                <CountUp start={0} end={12} duration={3} delay={0} />
              </span>
              <div>Улс хоорондын чиглэл</div>
            </div>
            
            <div className="col-6 col-lg-3 col-sm-6 fadeInRight">
              <p>
                <img src={PictureInfo3} height="60px" alt="zz" />
              </p>
              <span className="counter">
                <CountUp start={0} end={861} duration={3} delay={0} />
              </span>
              <span>+</span>
              <div>Тээврийн хэрэгсэл</div>
            </div>

            <div className="col-6 col-lg-3 col-sm-6 fadeInRight">
              <p>
                <img src={PictureInfo4} height="60px" alt="zz" />
              </p>
              <span className="counter">
                <CountUp start={0} end={2718000} duration={3} delay={0} />
              </span>
              <span>+</span>
              <div>Зорчигч</div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Infograph;
