import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = () => {
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <div className="search-wrapper">
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Хайх утгаа оруулна уу..."
        className="search-input"
      />
      <button onClick={handleSearchClick} className="search-button">
      <i className='fas fa-search'></i>
      </button>
    </div>
  );
};

export default Search;
