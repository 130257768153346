// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infograph {
  min-height: 220px;
  width: 100%;
  display: block;
  text-align: center;
  justify-content: center;
  padding: 40px 0;
  color: black;
  font-size: 15px;
}

.infograph span {
  color: rgb(80, 200, 210);
  font-size: 21px;
}`, "",{"version":3,"sources":["webpack://./src/components/Infograph/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".infograph {\n  min-height: 220px;\n  width: 100%;\n  display: block;\n  text-align: center;\n  justify-content: center;\n  padding: 40px 0;\n  color: black;\n  font-size: 15px;\n}\n\n.infograph span {\n  color: rgb(80, 200, 210);\n  font-size: 21px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
