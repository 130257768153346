// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Table container for horizontal scrolling */
.table-container {
    width: 100%;
    overflow-x: none;
    margin: 0 auto;
  }
  
  /* Table styles */
  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto; /* Allows table to adapt to the available space */
  }
  
  /* Responsive adjustments */
  @media (max-width: 800px) {
    .table th, .table td {
      font-size: 9px; /* Reduce font size for smaller screens */
      padding: 2px;    /* Adjust padding */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Uilchilgee/UneTarif/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA,6CAA6C;AAC7C;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;EAChB;;EAEA,iBAAiB;EACjB;IACE,WAAW;IACX,yBAAyB;IACzB,kBAAkB,EAAE,iDAAiD;EACvE;;EAEA,2BAA2B;EAC3B;IACE;MACE,cAAc,EAAE,yCAAyC;MACzD,YAAY,KAAK,mBAAmB;IACtC;EACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n/* Table container for horizontal scrolling */\n.table-container {\n    width: 100%;\n    overflow-x: none;\n    margin: 0 auto;\n  }\n  \n  /* Table styles */\n  .table {\n    width: 100%;\n    border-collapse: collapse;\n    table-layout: auto; /* Allows table to adapt to the available space */\n  }\n  \n  /* Responsive adjustments */\n  @media (max-width: 800px) {\n    .table th, .table td {\n      font-size: 9px; /* Reduce font size for smaller screens */\n      padding: 2px;    /* Adjust padding */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
